/*IMPORTS*/
@import "~react-vis/dist/style";
/*IMPORTS*/

/*Test*/
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

th, td {
    padding: 5px 12px;
}

/*Test*/

/*Variables*/
:root {
    --bgcolor_primary: #f1f1f1;
    --scrollbar_width: 0.8vw;
    --scrollbar_bradius: 1vw;
    --scrollbar_bg: #324358;
    --box-shadow: rgba(114, 114, 114, 0.288);
    --header-color: #008ee0;
    --search-color: #004b77;
    --primary_color: #af2c15;
    --second_color: #004557;
}

/*Variables*/

/**/
@font-face {
    font-family: 'alegraya-regular';
    src: local('AlegrayaSans'), url(../../fonts/AlegreyaSans-Regular.ttf);
}

/**/
/*General*/
body {
    width: 100%;
    min-height: 100vh;
    background-color: var(--bgcolor_primary);
    font-family: 'alegraya-regular';
}

img {
    pointer-events: none;
}

a {
    text-decoration: none;
    color: currentColor;
}

.line {
    width: 100%;
    height: 0.1vh;
    background: #3243585d;
    border-radius: 12px;
}

::-webkit-scrollbar {
    width: var(--scrollbar_width);
}

::-webkit-scrollbar-track {
    background-color: var(--bgcolor_primary);
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar_bg);
    border-radius: var(--scrollbar_bradius);
}

.header {
    display: flex;
    background-color: var(--header-color);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 2px 2px var(--box-shadow);
}

.header_div {
    padding-left: 2vw;
    display: flex;
    align-items: center;
    flex: 1;
}

.h_div_2 {
    justify-content: flex-end;
    padding-right: 2vw;
}

.search {
    background-color: var(--search-color);
    border-radius: 4px;
    width: 45%;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.no-display{
    display: none;
}

.search input {
    outline: none;
    border: none;
    background: none;
    color: #ffffff;
    padding-left: 12px;
    width: 100%;
}

.search input::placeholder {
    color: #acacac;
}

.account_logo {
    width: 45px;
    height: 45px;
    margin: 5px;
    background: #acacca;
    border-radius: 25px;
}

/*MENU*/
.hamburger {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    margin-right: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.h_line {
    width: 70%;
    height: 3px;
    margin: 2px;
    border-radius: 12px;
    background-color: #ffffff;
}

/*MENU*/

.fondo {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    opacity: 0.9;
    z-index: -100;
    filter: brightness(40%);
}

/*General*/


/*Login*/
.container_general {}

.login_card {
    min-width: 290px;
    margin: auto;
    margin-top: 25vh;
    min-height: 50vh;
    width: 40%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5vh;
    box-shadow: 1px 1px 2px 2px var(--box-shadow);
    transition: all .2s;
    position: relative;
}

.login_card::before {
    content: "";
    min-height: 50vh;
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 6vh;
    border-radius: 0.5vh;
    left: 1.5vw;
    background-color: var(--primary_color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login_title {
    width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    flex: 0.5;
}

.login_title .top-letters {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.icon-login {
    height: auto;
    width: 90px;
    flex: 0.2;

}

.login_title h3 {
    font-weight: 100;
    font-size: 35px;
    color: #6d6d6d;
}

.aux-line-login {
    height: 2px;
    width: 16vw;
    background: #6d6d6d;
    transform: translateY(-28px) translateX(-60%);
}

.login_form_container {
    flex: 1;
    width: 90%;
}

.login_form_container .input_container {
    width: 100%;
}

.login_form_container .input_container p {
    color: #333333;
}

.login_form_container .input_container input {
    border: solid 2px var(--primary_color);
    border-radius: 0.2vw;
    outline: none;
    width: 70%;
    height: 6vh;
    font-size: 0.9em;
    background: #ffffff;
    text-align: center;
    margin: 5px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.button-line-login {
    cursor: pointer;
    outline: none;
    background: #ffffff;
    width: 100%;
    height: 6vh;
    display: flex;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #6d6d6d;
    border: solid 2px var(--second_color);
    border-radius: 0.2vw;
    transition: all .2s;
    font-size: 80%;
}

.button-line-login:hover {
    background: var(--second_color);
    color: #ffffff;
}

.error_container {
    height: 5vh;
    text-align: center;
    color: #cc3433;
    font-weight: bold;
}

/*Login*/
/*Dashboard*/
.dashboard-body {
    display: flex;
    flex-direction: column;
}

.left-side-dashboard {
    margin-top: 30px;
    padding: 50px;
    display: flex;
    flex-direction: column;
}

.icon-dash {
    height: auto;
    width: 160px;
    margin-bottom: 60px;
}

.right-side-dashboard {
    align-items: center;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
}

.dashboard_search button {
    border: none;
    outline: none;
    color: #ffffff;
    width: 10vw;
    height: 20vh;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all .3s;
}
.dashboard_search button:hover {
    border: none;
    outline: none;
    color: #ffffff;
    width: 10vw;
    height: 20vh;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    transition: all .3s;
}

/*Dashboard*/
/*Loading*/
.loading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../../images/backgrounds/loading_back.png");
    background-size: 100% 100vh;
    /*Tamaño de la pantalla*/
    width: 100%;
    min-height: 100vh;
}

/*Loading*/
/*Recover password*/
.recover_card {
    min-width: 320px;
    margin: auto;
    margin-top: 25vh;
    min-height: 50vh;
    width: 41%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5vh;
    box-shadow: 1px 1px 2px 2px var(--box-shadow);
    transition: all .2s;
    position: relative;
}

.recover_card::before {
    content: "";
    min-height: 50vh;
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 6vh;
    border-radius: 0.5vh;
    left: 1.5vw;
    background-color: var(--primary_color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.recover_title {
    width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    flex: 0.5;
}

.recover_title .top-letters {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.recover_title .icon-login {
    height: auto;
    width: 90px;
    flex: 0.2;

}

.recover_title h3 {
    font-weight: 100;
    font-size: 34px;
    color: #6d6d6d;
}

.aux-line-recover {
    height: 2px;
    width: 30vw;
    background: #6d6d6d;
    transform: translateY(-28px) translateX(-10%);
}

.recover_form_container {
    width: 90%;
}

.recover_form_container .input_container {
    width: 100%;
}

.recover_form_container .input_container p {
    color: #333333;
}

.recover_form_container .input_container input {
    border: solid 2px var(--primary_color);
    border-radius: 0.2vw;
    outline: none;
    width: 70%;
    height: 6vh;
    font-size: 0.9em;
    background: #ffffff;
    text-align: center;
    margin: 5px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.recover_form_container .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    margin-bottom: 0;
}

.recover_form_container .button-line-login {
    cursor: pointer;
    outline: none;
    background: #ffffff;
    width: 100%;
    height: 6vh;
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #6d6d6d;
    border: solid 2px var(--second_color);
    border-radius: 0.2vw;
    transition: all .2s;
    font-size: 80%;
}

.recover_form_container .button-line-login:hover {
    background: var(--second_color);
    color: #ffffff;
}

.recover_card .popUp {
    height: 100%;
}

.recover_card .popUp button {
    border: none;
    background-color: #ffffff;
    transition: all 0.5s;
    font-size: 22px;
    color: #6d6d6d;
    pointer-events: auto;
    cursor: pointer;
}

.recover_card .popUp button:hover {
    color: #cc3433;
}

.recover_card .popUp-text {
    text-align: center;
    display: flex;
    justify-content: center;
}

.recover_card .popUp-text h3 {
    margin-top: 8%;
    font-weight: 100;
    font-size: 32px;
    color: #6d6d6d;
    width: 50%;
}

/*Recover password*/


/*Create user*/
@media screen and (max-width:500px) {
    .create-user_form_container .second-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .create-user_form_container .second-group input {
        width: 100%;
    }

    .create-user_form_container .third-group {
        width: 100% !important;
    }

    .create-user_form_container .fourth-group .input_container i {
        display: none;
    }
}

.create-user_card {
    min-width: 340px;
    margin: auto;
    margin-top: 3.5vh;
    margin-bottom: 10px;
    min-height: 50vh;
    width: 70%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5vh;
    box-shadow: 1px 1px 2px 2px var(--box-shadow);
    transition: all .2s;
    position: relative;
}

.create-user_card::before {
    content: "";
    min-height: 92vh;
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 3vh;
    border-radius: 0.5vh;
    left: 1.6vw;
    background-color: var(--second_color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.create-user_title {
    width: 98%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    flex: 0.5;
}

.create-user_title .top-letters {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.create-user_card .icon-login {
    height: auto;
    width: 90px;
    flex: 0.1;

}

.create-user_title h3 {
    font-weight: 100;
    font-size: 30px;
    color: #6d6d6d;
}

.create-user_card .aux-line {
    height: 2px;
    width: 25vw;
    background: #6d6d6d;
    transform: translateY(-21px) translateX(-85%);
}

.create-user_form_container {
    width: 95%;
}

.create-user_form_container h2 {
    font-weight: 400;
    font-size: 29px;
    color: var(--primary_color);
    margin: 0;
}

.create-user_form_container .input_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.create-user_form_container .input_container p {
    font-weight: 200;
    color: #333333;
    font-size: 20px;
    min-width: fit-content;
}

.create-user_form_container .input_container input {
    border: solid 2px var(--primary_color);
    border-radius: 0.2vw;
    outline: none;
    width: 100%;
    height: 5vh;
    font-size: 0.9em;
    background: #ffffff;
    text-align: start;
    margin: 5px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.create-user_form_container .first-group {
    display: flex;
    flex-direction: row;
}

.create-user_form_container .first-group select {
    width: 100%;
    position: relative;
}

.create-user_form_container .first-group .input_container i {
    transform: rotate(-45deg) translateX(6px)translateY(-39px);
}

.create-user_form_container .second-group {
    display: flex;
    flex-direction: row;
}

.create-user_form_container .second-group input {
    width: 40%;
}

.create-user_form_container .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    margin-bottom: 0;
}

.create-user_form_container .third-group {
    width: 50%;
}


.create-user_form_container .input_container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: solid 2px var(--primary_color);
    border-radius: 0.2vw;
    outline: none;
    height: 5vh;
    font-size: 0.9em;
    background: #ffffff;
    text-align: start;
    margin: 0px;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
    padding: 7px 10px;
    color: #929292;
    position: relative;
    transition: all 0.25s ease;
}

.create-user_form_container .input_container select:hover {
    background: var(--primary_color);
    color: #ffffff;
}

.create-user_form_container .input_container i {
    position: sticky;
    right: 20px;
    top: calc(50% - 13px);
    width: 10px;
    height: 10px;
    border-left: 2px solid var(--primary_color);
    border-bottom: 2px solid var(--primary_color);
    transition: all 0.25s ease;
}

.create-user_form_container .fourth-group .input_container i {
    transform: rotate(-45deg) translateX(-15px)translateY(-21px);
}

.create-user_form_container .input_container:hover i {
    margin-top: 6px;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
}

.create-user_form_container .fourth-group {
    display: flex;
    flex-direction: row;
}

.create-user_form_container .fourth-group .input_container {
    justify-content: right;
}

.create-user_form_container .fourth-group select {
    width: 60%;
    position: relative;
}

.create-user_card ::-ms-expand {
    display: none;
}

.create-user_form_container .button-line-login {
    cursor: pointer;
    outline: none;
    background: #ffffff;
    width: 100%;
    height: 6vh;
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #6d6d6d;
    border: solid 2px var(--second_color);
    border-radius: 0.2vw;
    transition: all .2s;
    font-size: 80%;
}

.create-user_form_container .button-line-login:hover {
    background: var(--second_color);
    color: #ffffff;
}

/*Create user*/
/*Edit permisos*/
.permisos_card {
    min-width: 320px;
    margin: auto;
    margin-top: 10vh;
    min-height: 50vh;
    width: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5vh;
    box-shadow: 1px 1px 2px 2px var(--box-shadow);
    transition: all .2s;
    position: relative;
    padding: 2%;
}

.permisos_card::before {
    content: "";
    min-height: 100%;
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 6vh;
    border-radius: 0.5vh;
    left: 1.5vw;
    background-color: var(--second_color);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.permisos-top-part {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.permisos_title {
    width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    flex: 0.5;
}

.permisos_title .top-letters {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.permisos_title .icon-login {
    height: auto;
    width: 90px;
    flex: 0.1;

}

.permisos_title h3 {
    font-weight: 100;
    font-size: 34px;
    color: #6d6d6d;
}

.aux-line-permisos {
    height: 2px;
    width: 21vw;
    min-width: 120px;
    background: #6d6d6d;
    transform: translateY(-28px) translateX(7%);
}

.permisos-top-part button {
    border: none;
    background-color: #ffffff;
    transition: all 0.3s;
    font-size: 27px;
    color: #6d6d6d;
    display: flex;
    pointer-events: auto;
    cursor: pointer;
}

.permisos-top-part button:hover {
    color: #cc3433;
}

.permisos-middle-part {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.permisos_card .module {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.permisos_card .module-prop {
    border: solid 2px var(--second_color);
    width: 75%;
    height: 5vh;
    margin: 10px;
    display: flex;
    align-items: center;
}

.module-prop p {
    margin: 0px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 300;
}

.permisos_card .module img {
    pointer-events: auto;
    cursor: pointer;
}

.permisos-bottom-part {
    width: 100%;
    display: flex;
    margin-top: 50px;
}

.permisos-bottom-part .add-module {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.permisos-bottom-part .add-module .module-prop {
    border: solid 2px var(--second_color);
    width: 75%;
    height: 5vh;
    margin: 10px;
    display: flex;
    align-items: center;
    outline: none;
    font-size: 20px;
    font-weight: 100;
}

.permisos-bottom-part .add-module img {
    pointer-events: auto;
    cursor: pointer;
    height: 35px;
    transition: all 0.15s;
}

.permisos-bottom-part .add-module img:hover {
    filter: brightness(200%);
}

.permisos-bottom-part .add-module button {
    outline: none;
    border: none;
    background-color: #ffffff;
}

/*Edit Permisos*/

/*Edit roles*/
.roles_card {
    min-width: 320px;
    margin: auto;
    margin-top: 10vh;
    min-height: 50vh;
    width: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5vh;
    box-shadow: 1px 1px 2px 2px var(--box-shadow);
    transition: all .2s;
    position: relative;
    padding: 2%;
}

.roles_card::before {
    content: "";
    min-height: 100%;
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 6vh;
    border-radius: 0.5vh;
    left: 1.5vw;
    background-color: var(--second_color);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.roles-top-part {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.roles_title {
    width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    flex: 0.5;
}

.roles_title .top-letters {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.roles_title .icon-login {
    height: auto;
    width: 90px;
    flex: 0.1;

}

.roles_title h3 {
    font-weight: 100;
    font-size: 34px;
    color: #6d6d6d;
}

.aux-line-roles {
    height: 2px;
    width: 21vw;
    min-width: 120px;
    background: #6d6d6d;
    transform: translateY(-28px) translateX(7%);
}

.roles-top-part button {
    border: none;
    background-color: #ffffff;
    transition: all 0.3s;
    font-size: 27px;
    color: #6d6d6d;
    display: flex;
    pointer-events: auto;
    cursor: pointer;
}

.roles-top-part button:hover {
    color: #cc3433;
}

.roles-middle-part {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.roles_card .module {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.roles_card .module-prop {
    border: solid 2px var(--second_color);
    width: 75%;
    height: 5vh;
    margin: 10px;
    display: flex;
    align-items: center;
}

.module-prop p {
    margin: 0px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 300;
}

.roles_card .module img {
    pointer-events: auto;
    cursor: pointer;
}

.roles-bottom-part {
    width: 100%;
    display: flex;
    margin-top: 50px;
}

.roles-bottom-part .add-module {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.roles-bottom-part .add-module .module-prop {
    border: solid 2px var(--second_color);
    width: 75%;
    height: 5vh;
    margin: 10px;
    display: flex;
    align-items: center;
    outline: none;
    font-size: 20px;
    font-weight: 100;
}

.roles-bottom-part .add-module img {
    pointer-events: auto;
    cursor: pointer;
    height: 35px;
    transition: all 0.15s;
}

.roles-bottom-part .add-module img:hover {
    filter: brightness(200%);
}

.roles-bottom-part .add-module button {
    outline: none;
    border: none;
    background-color: #ffffff;
}

/*Edit roles*/
/*bills test*/

.bills-test {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.bills-test button {
    cursor: pointer;
    outline: none;
    background: #ffffff;
    width: 20%;
    height: 6vh;
    display: flex;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #6d6d6d;
    border: solid 2px var(--second_color);
    border-radius: 0.2vw;
    transition: all .2s;
    font-size: 80%;
}

.bills-test button:hover {
    background: var(--second_color);
    color: #ffffff;
}

.bills-test table td {
    padding: 2px 6px;
}

/*bills test*/

/*DASHBOARD ASUNTOS*/
.body_container {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.search_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    overflow: hidden;
    height: 40px;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
    width: 50%;
    margin: 0 12px;
}

.search_input {
    outline: none;
    border: none;
    padding-left: 12px;
    flex: 1;
}

.search_button {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #ffffff;
}

.search_img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.filters_container {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 10vh;
    z-index: 1;
}

.table_card {
    margin-top: 2vh;
    margin-bottom: 2vh;
    background-color: #ffffff;
    overflow-x: scroll;
    border-radius: 12px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
}

.table_card th, td {
    text-align: center;
    transition: all .1s;
}

.table_card td {
    cursor: pointer;
}

.table_card td:hover {
    background-color: #3243583f;
}

.table_card table, th, td {
    border: 1px solid #bbbbbb;
}

.table_card table {
    background-color: #ffffff;
    border-radius: 2px;
}

.return_button {
    margin: 0 12px;
    outline: none;
    border: none;
    width: 25%;
    height: 40px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: #ffffff;
    cursor: pointer;
    transition: all .1s;
    opacity: 1;
}

.return_button.no_display {
    opacity: 0;
    transition: all .1s;
}

.return_button:hover {
    background-color: #cc3433;
    color: #ffffff;
}

.general-filters {
    margin-top: 10vh;
    background-color: #ffffff;
    padding: 50px;
    border-radius: 10px;
}

.general-filters .form-group {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
}

.general-filters p {
    width: 50%;
    text-align: right;
}

.general-filters .input-case {
    width: 50%;
}

.general-filters input {
    width: 80%;
    height: 20px;
    margin-left: 20px;
    outline: none;
    border: 1px solid#af2c15;
    border-radius: 3px;
    padding-left: 12px;
    flex: 1;
}

.general-filters select {
    width: 80%;
    height: 20px;
    margin-left: 20px;
    outline: none;
    border: 1px solid#af2c15;
    border-radius: 3px;
    padding-left: 12px;
    flex: 1;
}
.report-case{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.report-case button{
    border: 1px solid var(--primary_color);
    background-color: #ffffff;
    padding: 5px;
    transition: all 0.3s;

}
.report-case button:hover{
    border: 1px solid #333333;
    background-color: var(--primary_color);
    color: #fafafa;
}
/*DASHBOARD ASUNTOS*/

/*New factura venta*/
.new-factura {
    margin-top: 10vh;
    margin-bottom: 10vh;
    background-color: #ffffff;
    padding: 50px;
    border-radius: 10px;
    width: 30vw;
}

.new-factura .form-group {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
}

.new-factura p {
    width: 40%;
    text-align: right;
}

.new-factura .input-case {
    width: 90%;
}

.new-factura input {
    width: 90%;
    height: 20px;
    margin-left: 20px;
    outline: none;
    border: 1px solid#af2c15;
    border-radius: 3px;
    padding-left: 12px;
    flex: 1;
}

.new-factura select {
    width: 90%;
    height: 20px;
    margin-left: 20px;
    outline: none;
    border: 1px solid#af2c15;
    border-radius: 3px;
    padding-left: 12px;
    flex: 1;
}
/*New factura venta*/
