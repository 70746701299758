.graph-overlay{
    width: 32vw;
    height: auto;
    background-color: #f1f1f1;
    border: solid 1px #6d6d6d;
    display: flex;
    flex-direction: column;
    margin:10px
}
.graph-overlay .top-part{
    display: flex;
    flex-direction: row;
    padding: 7px;
}
.graph-overlay .top-part p{
    margin:0px;
    font-weight: 700;
    color: #6d6d6d;
}

/*Side bar*/
.nav-master{
    display: flex;
    flex-direction: column;
}

.side-bar{
    margin-top: 8.5vh;
    background-color: white;
    width: 250px;
    height: 93vh;
    overflow-y: scroll;
    left: 0;
    position: fixed;
    transition: all 0.2s;
    z-index: 2;
}

.side-bar.no-desplegado {
    left: -100%;
    transition: all 0.5s;
}
.side-bar .info-user{
    border-bottom: 2px solid #272120;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}
.info-user h5{
    margin: 0px;
    margin-top:10px; ;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.3;
    color: rgb(32, 32, 32);
    font-size: 1.2rem;
}
.info-user small {
    color: #282a2c;
    font-size: 90%;
    font-weight: 500;
    font-family: 'alegraya-regular';
}
.info-user .image-aux{
    background-color: rgb(71, 70, 70);
    width: 100px;
    height: 100px;
    border-radius: 100px;
    align-self: center;
}
.side-bar .side-menu {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin: 10px;
}
.side-menu .header-li {
    color: #adb5bd;
    padding-left: 20px;
    margin-top: 20px;
    font-size: 12px;
}
.side-menu>li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}
.side-menu>li.active a {
    background: #aab7c5;
    color: #000000;
}
.side-menu>li a:hover {
    background: #ced4da;
    color: #292b30;
}
.side-menu a {
    transition: all 0.3s ease-out;
    vertical-align: middle;
    border-radius: 3px;
    color: #303030;
    padding: 10px 15px;
    outline-width: 0;
    cursor: pointer;
}
.side-bar .separator{
    margin-top: 2px;
    width: 100%;
    height: 2px;
    background-color:#272120;
}
.side-bar .submenu{
    padding-inline-start: 0px;
    margin-left: 20px;
    list-style: none;
    border-left: solid 2px #303030;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-in;
}

.side-bar .submenu.active{
    max-height: 400px; 
}
.submenu>li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}
/*Side Bar*/
/*Header*/
.header{
    background-color: white;
    width: 100vw;
    height: 8.5vh;
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.3);
    align-items: center;
    z-index: 3;
}
.hamburger {
    margin-left: 15px;
    border: none;
    background-color: rgba(0,0,0,0);
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all .2s;
}

.hamburger.open .line {
    position: absolute;
    margin: 2px;
    width: 35px;
    height: 5px;
    background-color: rgba(0,0,0,0);
    transition: all .2s;
}

.hamburger.open .line:first-child {
    margin: 2px;
    width: 35px;
    height: 5px;
    background-color: #af2c15;
    transform: rotateZ(-45deg);
}

.hamburger.open .line:last-child{
    margin: 2px;
    width: 35px;
    height: 5px;
    background-color: #af2c15;
    transform: rotateZ(45deg);
}

.hamburger .line {
    margin: 2px;
    width: 35px;
    height: 5px;
    background-color: black;
    transition: all .2s;
}

.search-box{
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.search-box input{
    outline: none;
    border: none;
    background: #fff;
    height: 100%;
    font-size: 20px;
    border-radius: 5px 0px 0px 5px;

}
.search-box button{
    height: 27px;
    border: none;
    background-color: rgba(0,0,0,0);
    align-items: center;
    display: flex;
    border-radius: 0px 5px 5px 0px;
    transition: all 0.5s;
    cursor: pointer;
}
.search-box img{
    height: 20px;
    width: auto;
    margin: 8px;
}

.left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 150px;
    height: 50px;
    margin: 2px 12px;
    margin-right: 25px;
}

.logo_img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/*Header*/

/*Modal*/
.modal-case{
    height: auto;
    width: 100%;
    align-items: center;
    display: flex;
}
.modal {
    position: fixed;
    top: 8.5vh;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    outline: none;
    border: none;
    align-items: center;
    flex-direction: column;
    overflow-x: scroll;
}
.nmodal {display: none;}

.prime-modal{
    display: flex;
    background-color: white;
    flex: 1;
    margin: 12px;
    margin-top: 50vh;
    height: 80vh;
    width: 50%;
    border-radius: 12px;
    padding: 10px;
flex-direction: column;
}
.prime-modal .input-case{
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    width: 80%;
}

.prime-modal p {
    width: 20%;
    text-align: right;
}

.prime-modal input {
    width: 100%;
    height: 20px;
    margin-left: 20px;
    outline: none;
    border: 1px solid#af2c15;
    border-radius: 3px;
    padding-left: 12px;
    flex: 1;
    
}
.prime-modal button{
    outline: none;
    border: 1px solid#af2c15;
    border-radius: 3px;
    padding: 4px 15px;
    margin-left: 6px;
    transition: all 0.5s;
    background-color: #dddddd;
}
.prime-modal button:hover{
    background-color: #fff;
    
}
.submodal {
    display: flex;
    background-color: white;
    flex: 1;
    margin: 12px;
    height: 90vh;
    border-radius: 12px;
    padding: 10px;
    flex-direction: column;

}

/*Modal*/
